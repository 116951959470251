<template>
  <div id="basicLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <GlobalHeader />
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">
        更多可看:
        <a-link href="http://book.jarvlis.top" icon>综合图书信息平台</a-link>
        <a-divider direction="vertical" />
        <a-link href="https://github.com/Jarvlis">
          <template #icon>
            <icon-github />
          </template>
          Jarvlis的Github
        </a-link>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader";
import { IconGithub } from "@arco-design/web-vue/es/icon";

export default {
  components: { GlobalHeader, IconGithub },
};
</script>

<style scoped>
.header {
  background: inherit;
}

#basicLayout .content {
  padding: 20px;
}

#basicLayout .footer {
  padding: 16px;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
