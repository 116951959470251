/**
 * 用户的权限类别
 */
const ACCESS_ENUM = {
  NOT_LOGIN: "notLogin",
  ADMIN: "admin",
  USER: "user",
};

export default ACCESS_ENUM;
